import { PullToRefresh } from '@gagovictor/shared-frontend-core/dist/shared/components/PullToRefresh';
import { useAutoRefresh } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useAutoRefresh';
import AddIcon from '@mui/icons-material/Add';
import Masonry from '@mui/lab/Masonry';
import {
    Typography,
    Alert,
    Fab,
    Container,
    Snackbar,
    Button,
    Grow,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppDispatch, RootState } from '../../redux/store';
import { RefreshableProps } from '../../shared/models/refreshable';
import TaskCard from '../components/TaskCard';
import TaskModal from '../components/TaskModal';
import { Task, FetchTasksParams } from '../models/task';
import { fetchTasksAsync } from '../redux/tasksSlice';

const ArchivedTasksPage = ({ setRefreshAction }: RefreshableProps) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { tasks, fetchStatus, fetchError } = useSelector(
        (state: RootState) => state.tasks
    );
    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedTask, setSelectedTask] = useState<Task>();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState<
        'success' | 'error'
    >('success');
    const [fetchParams] = useState<FetchTasksParams>({
        page: 1,
        limit: 20,
        filters: {
            archived: true,
        },
    });

    const fetchTasks = useCallback(async () => {
        await dispatch(fetchTasksAsync(fetchParams));
    }, [dispatch, fetchParams]);

    useEffect(() => {
        dispatch(fetchTasksAsync(fetchParams));
    }, [dispatch, fetchParams]);

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    useEffect(() => {
        setRefreshAction(() => fetchTasks);

        return () => {
            setRefreshAction(undefined);
        };
    }, [fetchTasks, setRefreshAction]);

    useAutoRefresh({
        onRefresh: fetchTasks,
        interval: 60000,
        immediate: false,
        onlyWhenFocused: true,
    });

    const handleCreateTask = () => {
        setEditMode(false);
        setModalOpen(true);
    };

    const handleEditTask = (task: Task) => {
        setSelectedTask(task);
        setEditMode(true);
        setModalOpen(true);
    };

    const onCloseTaskModal = () => {
        setEditMode(false);
        setModalOpen(false);
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const showSnackbar = (message: string, severity: 'success' | 'error') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const filteredTasks = tasks.filter(
        (task: Task) => task.archivedAt && !task.deletedAt
    );

    return (
        <>
            <PullToRefresh onRefresh={fetchTasks}>
                <Container
                    sx={{
                        width: '100%',
                        minHeight: 'calc(100vh - 64px)', // full screen height minus footer
                        paddingTop: {
                            xs: 'calc(32px + 56px)',
                            md: 'calc(32px + 64px)',
                        }, // Offset fixed app bar/header
                        position: 'relative',
                        paddingBottom: '32px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'wrap',
                        justifyContent: 'flex-start',
                        gap: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: 2,
                        }}
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontFamily:
                                    '"Uncial Antiqua", "Helvetica", "Arial", sans-serif',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundSize: '200% 200%',
                                animation:
                                    'logoGradientAnimation 20s ease infinite',
                                '@keyframes logoGradientAnimation': {
                                    '0%': { backgroundPosition: '0% 50%' },
                                    '50%': { backgroundPosition: '100% 50%' },
                                    '100%': { backgroundPosition: '0% 50%' },
                                },
                            }}
                        >
                            Tasks
                        </Typography>
                        <Typography variant="h5" sx={{ mt: '0.125em' }}>
                            (Archived)
                        </Typography>
                    </Box>

                    {fetchStatus === 'failed' && (
                        <Alert severity="error" data-testid="fetch-error-alert">
                            {fetchError}
                        </Alert>
                    )}
                    {(fetchStatus === 'succeeded' ||
                        filteredTasks.length > 0) && (
                        <Masonry
                            columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                            spacing={2}
                            data-testid="masonry"
                        >
                            {filteredTasks.map((task: Task, index: number) => (
                                <Grow
                                    key={task.id}
                                    in={true}
                                    timeout={(index + 1) * 300} // Staggered delay for animation
                                >
                                    <Box data-testid="task-card">
                                        <TaskCard
                                            task={task}
                                            onEdit={() => handleEditTask(task)}
                                            showSnackbar={showSnackbar}
                                        />
                                    </Box>
                                </Grow>
                            ))}
                        </Masonry>
                    )}
                    {fetchStatus === 'succeeded' &&
                        filteredTasks.length === 0 && (
                            <Box
                                sx={{
                                    position: 'relative',
                                    paddingBottom: '32px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    display: 'flex',
                                }}
                            >
                                <Typography variant="h6" gutterBottom>
                                    No tasks archived
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/tasks')}
                                    aria-label="Back to Tasks"
                                >
                                    Back to Tasks
                                </Button>
                            </Box>
                        )}
                </Container>
            </PullToRefresh>

            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={handleCreateTask}
            >
                <AddIcon />
            </Fab>

            <TaskModal
                open={modalOpen}
                onClose={onCloseTaskModal}
                task={selectedTask}
                mode={editMode ? 'edit' : 'create'}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ArchivedTasksPage;
